.drawer {
  :global {
    .ant-drawer-body {
      padding: 0 !important;
    }
  }
}

.card {
  :global {
    .ant-card-body {
      padding-left: 60px;
    }
  }
}

.rowCard {
  :global {
    .ant-card-body {
      padding: 20px;
    }
  }
}

.live {
  > div {
    width: 50%;
  }
}
